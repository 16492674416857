<template>
  <div class="Home">
    <div class="page-header">
      <div class="page-title">MIC: Model Identity Card</div>
      <div class="page-subtitle">
        Toward a standard to characterize simulation models
      </div>
    </div>
    <div class="main-content">
      <div class="main-content-left">
        <div class="main-content-title">
          For a better characterization of simulation models
        </div>
        <div class="main-content-text">
          <p>
            Simulation became a key enabler for the development of various
            systems, from their early development to their validation.
            Simulation needs are particularly important for complex systems.
            They include a large number of interacting components, and their
            behavior is difficult to predict without extensive computations.
          </p>

          <p>
            The increasing use of simulation requires a better management of the
            simulation models. Simulation models need to be characterized with a
            variety of key information covering, for example, the represented
            system, the modelling choices, the hardware and software
            integration, or the licenses constraining their use. This
            characterization can have different purposes depending on the
            lifecycle state of the simulation model.
          </p>

          <p>
            In the past, most systems were validated with a limited number of
            physical tests representing worst case scenarios. Indeed, physical
            tests were relatively expensive. Simulation can offer predictions at
            a lower cost and permit to cover a greater variety of usage. For all
            performances associated to safety, a mix of physical tests and
            simulation becomes mandatory to ensure a robust validation.
          </p>

          <p>
            <b
              >The Model Identity Card (MIC) offers a reference list of key
              information to characterize simulation models,</b
            >
            both for specification and description. The MIC is made of fields
            organized in groups.
          </p>
        </div>
        <div class="main-content-title second-title">
          A standard common denominator of simulation characteristics
        </div>
        <div class="main-content-text">
          <p>
            The MIC is defined as the set of core information which appears to
            be useful to characterize most simulations models in most contexts.
            It does not intend to comprehensively cover every aspect of any
            simulation model. The MIC can be considered as a standard common
            denominator of simulation characteristics.
          </p>
          <p>
            Please send all the comments and feedbacks you may have about the
            MIC through the contact page. They will be analyzed by the different
            partners working on the MIC (see the partners page) to improve its
            definition. Please feel also free to use the contact page if you
            would like to become a partner and join us in this work.
          </p>
          <br />
        </div>
        <div class="main-content-title second-title">
          <p>Organization of the MIC:</p>
        </div>
        <img class="illustration" src="@/assets/images/diagramme_mic.png" />
      </div>
      <Content-Right />
    </div>
    <Copyright />
  </div>
</template>

<script>
import ContentRight from "@/components/ContentRight.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  name: "MainContent",
  components: {
    ContentRight,
    Copyright,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>


<style lang="scss" scoped>
.illustration {
  width: 74%;
  margin-left: 13%;
  margin-top: 50px;
}
.page-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin-left: 10%;
  margin-top: 150px;
}

.page-subtitle {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin-left: 10%;
  margin-top: 10px;
}
.main-content {
  flex: 1;
  margin: 70px 10% 0 10%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.page-header {
  width: 100%;
  background-image: url("~@/assets/images/shutterstock_1318588295.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: -200px;
  height: 390px;
  overflow: hidden;
  position: relative;
}
.main-content-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
}

.main-content-text {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  padding-top: 20px;
}

.main-content-illustration {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.main-content-illustration .sample-wrapper {
  width: 48%;
}

.main-content-illustration .sample-wrapper .sample-image {
  overflow: hidden;
}
.main-content-illustration .sample-wrapper img {
  width: 100%;
  height: 17vw;
}

.illustration-legend {
  text-align: left;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 8px;
  letter-spacing: 0.8px;
  color: #666666;
  width: 332px;
  display: block;
  margin-bottom: 20px;
}

.second-title {
  margin-top: 40px;
}
</style>