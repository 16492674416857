<template>
  <div class="additional-content">
    <div class="bibtex">
      <h3>Cite the MIC:</h3>
      <div class="citation">
        <p class="citation-text">
          IRT SystemX (2020). Model Identity Card (MIC): Towards a standardization of the specification and description of simulation models.
          <a href="https://mic.irt-systemx.fr">https://mic.irt-systemx.fr</a>
        </p>
        <p class="citation-text">
          <b>BibTeX:</b> <br />@techreport{mic_2020, <br />
          <span class="tab"
            >title = {Model {Identity} {Card} ({MIC}): {Towards} a standardization of the specification and description of simulation models}, </span
          ><br /><span class="tab">url = {https://mic.irt-systemx.fr}, </span
          ><br /><span class="tab"
            >institution = {IRT SystemX}, year = {2020} }</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

<style lang="scss" scoped>
.citation-text {
  padding-left: 10px;
}
.tab {
  padding-left: 30px;
}
.licence,
.bibtex {
  font-family: "Open Sans", Arial, sans-serif;
}
.additional-content {
  flex: 1;
  margin: 70px 10% 0 10%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

.main-content-licensing {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  margin-top: 20px;
}

.citation {
  font-family: "Courier New";
  font-size: 12px;
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 10px;
}
</style>